<template>
  <b-container class="bg-white py-4">
    <b-row class="mb-4" v-if="!permissions.readUserGroups">
      <b-col>
        No access
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permissions.readUserGroups && !loading">
      <b-col>
        <b-container>
          <b-row>
            <b-col>
              <b-table id="regtable"
                striped
                :fields="fields"
                :items="groups"
              ></b-table>
            </b-col>
          </b-row>
          <b-row v-if="permissions.createUserGroups">
            <b-col>
              <b-form-input v-model="newgroup" placeholder="Enter new group"></b-form-input>
              <div class="mt-4" v-if="validated">
                <b-button variant="primary" v-on:click="saveGroup">Save</b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// import _ from 'lodash'
import ac from '../libs/accesscontrol'

export default {
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validated: function () {
      return this.newgroup.length > 2
    }
  },
  data () {
    return {
      fields: [
        {
          key: 'id',
          sortable: true
        },
        {
          key: 'name',
          sortable: true
        }
      ],
      groups: [],
      loading: true,
      newgroup: '',
      permissions: {}
    }
  },
  created: function () {
    this.$stat.log({ page: 'user groups', action: 'open user groups' })
    this.permissions.readUserGroups = ac.can(this.user.acgroups).readAny('userGroup').granted
    this.permissions.createUserGroups = ac.can(this.user.acgroups).createAny('userGroup').granted
    if (this.permissions.readUserGroups) {
      this.load()
    } else {
      this.$router.push({ name: 'Start' })
    }
  },
  methods: {
    load: async function () {
      this.groups = await this.$Amplify.API.get('cosmos', '/standard/usergroup')
      this.loading = false
    },
    saveGroup: async function () {
      this.loading = true
      try {
        const params = {
          body: {
            name: this.newgroup
          }
        }
        await this.$Amplify.API.put('cosmos', '/standard/usergroup', params)
        this.newgroup = ''
        this.load()
      } catch (e) {
        this.$logger.warn('saving error' + e)
      }
    }
  }
}
</script>

<style>
</style>
